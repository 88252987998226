import { User } from "../user/model/user.model";
export class Session {
    get currentMembership() {
        var _a;
        return ((_a = this._user.memberships.find((membership) => membership.client === this.currentClient)) !== null && _a !== void 0 ? _a : this._user.memberships[0]);
    }
    get user() {
        return this._user;
    }
    hasPermission(permissionName) {
        return this.currentMembership.hasPermission(permissionName);
    }
    hasRole(role) {
        return this.currentMembership.hasRole(role);
    }
    static deserializer(sessionObject) {
        const session = new Session();
        session._user = User.deserializer(sessionObject);
        return session;
    }
}
