import { Client } from "../client/client.model";
import { Membership } from "../membership/membership.model";
import { MembershipRole } from "../membership/membership-role.type";
import { PermissionName } from "../permission";
import { IUserJSON, User } from "../user/model/user.model";

export class Session {
    // Indicates if user is logged in via admin - impersonated
    public demo?: true;
    public currentClient!: Client;
    private _user!: User;

    get currentMembership(): Membership {
        return (
            this._user.memberships.find(
                (membership) => membership.client === this.currentClient
            ) ?? this._user.memberships[0]
        );
    }

    get user(): User {
        return this._user;
    }

    /**
     * Checks if currently logged-in user, in the context of the current client,
     * has a specific permission granted
     */
    public hasPermission(permissionName: PermissionName): boolean {
        return this.currentMembership.hasPermission(permissionName);
    }

    /**
     * Checks if currently logged-in user, in the context of the current client,
     * has a specific role granted
     */
    public hasRole(role: MembershipRole): boolean {
        return this.currentMembership.hasRole(role);
    }

    public static deserializer(sessionObject: IUserJSON): Session {
        const session: Session = new Session();
        session._user = User.deserializer(sessionObject);
        return session;
    }
}
