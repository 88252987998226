import { Api } from "@codewise/voluum-frontend-framework/api";
import {
    IDocument,
    IRemoteApi,
    Remote,
    Serializer,
} from "@codewise/voluum-frontend-framework/repository";
import { HttpStatus } from "@voluum-panel-shared/http-data-access";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import { simpleHttpService } from "./util/simple-http-service";

export interface IEventBus {
    showLoadingBar: () => void;
    hideLoadingBar: () => void;
    showHttpRequestError: (error: Error | Response) => void;
}

export class EventBusWiredRepository<T> extends Remote<T> {
    constructor(
        eventBus: IEventBus,
        serializer: Serializer<T>,
        apiConfig: IRemoteApi | string,
        document?: IDocument,
        public readonly apiService: Api = new Api(simpleHttpService)
    ) {
        super(apiService, serializer, apiConfig, document);

        this.isPending
            .pipe(
                map((isLoading) =>
                    isLoading ? "showLoadingBar" : "hideLoadingBar"
                )
            )
            .subscribe((notificationAction) => eventBus[notificationAction]());

        this.error.subscribe((error) => eventBus.showHttpRequestError(error));
    }

    public onRequestFailure(error: Response, entity: T): Observable<T> | never {
        if (error.status === HttpStatus.DEMO_ACCOUNT) {
            return of(entity);
        }
        throw error;
    }
}
