import { FeatureFlag } from "@voluum-panel-shared/flags-data-access";
import { Ajax } from "../../../service/ajax";
export class TagManager {
    constructor(key, eventBus, session, plan, promoSummary, _) {
        require("./tag_manager_core.js")(key);
        const context = window;
        const initialData = {
            event: "initialDataLayerData",
            client_ID: session.user.defaultClient.id,
            user_ID: session.user.id,
            client_plan: plan.code,
            user_status: session.user.state,
            first_registration: session.user.creationTime,
            sidenav: true,
            demo: !!session.demo,
            dsp_vertical_markets: promoSummary.hubspotProperties.dsp_vertical_markets,
            ai_driven_dominant_vertical: promoSummary.hubspotProperties.ai_driven_dominant_vertical,
            ai_driven_business_profile: promoSummary.hubspotProperties.ai_driven_business_profile,
            trk_genai_offer_vertical_labels: promoSummary.hubspotProperties.trk_genai_offer_vertical_labels,
            trk_survey_user_profile: promoSummary.hubspotProperties.trk_survey_user_profile,
            trk_survey_business_niche: promoSummary.hubspotProperties.trk_survey_business_niche,
            ai_drive_lead_generation_flag: promoSummary.hubspotProperties.ai_drive_lead_generation_flag,
            trk_owner: promoSummary.hubspotProperties.trk_owner,
            quickstart: session.currentClient.hasToggle(FeatureFlag.WIZARD),
            "AI nav": session.currentClient.hasToggle(FeatureFlag.AI_NAV),
            affilka_integration_disabled: session.currentClient.hasToggle("hideAffilkaIntegration"),
        };
        this.getEEAStatus()
            .then((response) => {
            initialData.b9e1a58e5807 = response.b9e1a58e5807;
        }, () => {
            initialData.b9e1a58e5807 = 2;
        })
            .then(() => {
            var _a;
            (_a = context.dataLayer) === null || _a === void 0 ? void 0 : _a.push(initialData);
        });
        eventBus.onUpdateDataLayer.subscribe((dataLayerData) => {
            var _a;
            dataLayerData.event = dataLayerData.event || "updateDataLayer";
            dataLayerData.eventLabel =
                dataLayerData.eventLabel || undefined;
            dataLayerData.eventValue =
                dataLayerData.eventValue || undefined;
            (_a = context.dataLayer) === null || _a === void 0 ? void 0 : _a.push(dataLayerData);
        });
    }
    getEEAStatus() {
        return Ajax.get("https://1706.codewise.com");
    }
}
