var _a;
import { __decorate, __metadata } from "tslib";
import { AutoSerialize } from "@codewise/voluum-frontend-framework/repository";
import { OnboardingChecklistAccountCardsModel } from "./onboarding-checklist-account-cards.model";
export class OnboardingChecklistAccountModel {
    constructor() {
        this.cards = new OnboardingChecklistAccountCardsModel();
    }
}
__decorate([
    AutoSerialize(),
    __metadata("design:type", typeof (_a = typeof OnboardingChecklistAccountCardsModel !== "undefined" && OnboardingChecklistAccountCardsModel) === "function" ? _a : Object)
], OnboardingChecklistAccountModel.prototype, "cards", void 0);
