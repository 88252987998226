import {
    IToastNotification,
    NotificationSubtypeEnum,
} from "@codewise/voluum-frontend-core/events";
import { HttpStatus } from "@voluum-panel-shared/http-data-access";
import { Observable } from "rxjs";
import { filter, map, scan, share } from "rxjs/operators";

import { EventBus } from "./event_bus";

export class HttpRequestErrorHandler {
    public readonly httpErrorMessage$: Observable<IHttpErrorMessage> =
        this.eventBus.onHttpRequestError.pipe(
            map(convertToHttpErrorMessage),
            share()
        );
    public readonly unauthorizedRequest$: Observable<IHttpErrorMessage> =
        this.httpErrorMessage$.pipe(
            filter((error) => error.status === HttpStatus.UNAUTHORIZED)
        );

    constructor(private eventBus: EventBus) {}

    public init(): void {
        this.httpErrorMessage$
            .pipe(
                filter(isHandledError),
                map((error: IHttpErrorMessage) => createNotification(error)),
                scan((previousNotification, currentNotification) => {
                    this.eventBus.hideToastNotification(previousNotification);
                    return currentNotification;
                })
            )
            .subscribe((notification) =>
                this.eventBus.showToastNotification(notification)
            );
    }
}

const NOT_HANDLED_ERROR_STATUSES: HttpStatus[] = [
    HttpStatus.BAD_REQUEST,
    HttpStatus.UNAUTHORIZED,
    HttpStatus.DEMO_ACCOUNT,
    HttpStatus.EXPECTATION_FAILED,
    HttpStatus.CONFLICT,
    HttpStatus.FOUND,
];

/**
 * List of error codes (that are defined on BE side) that should NOT be handled
 * globally (hopefully, they are handled locally).
 */
const NOT_HANDLED_ERROR_CODES: string[] = ["LOCKED"];

const CUSTOM_MESSAGES: IMessageMapper[] = [
    {
        test: (msg: IHttpErrorMessage): boolean =>
            msg.status === HttpStatus.REQUESTED_RANGE_NOT_SATISFIABLE,
        getMessage: () => "Time range passed is too long.",
    },
    {
        test: (msg: IHttpErrorMessage): boolean =>
            msg.status === HttpStatus.INSUFFICIENT_SUBSCRIPTION,
        getMessage: () =>
            "You are not authorized to perform requested action on this resource.",
    },
    {
        test: (msg: IHttpErrorMessage): boolean =>
            msg.status === HttpStatus.TOO_MANY_REQUESTS,
        getMessage: () =>
            "It appears you are trying to send too many requests at once. Please, wait a moment between requests.",
    },
    {
        test: (msg: IHttpErrorMessage): boolean =>
            msg.status === HttpStatus.ABORTED && navigator.onLine === false,
        getMessage: () =>
            "Your internet connection timed out during your request. Please, refresh your page.",
    },
    {
        test: (msg: IHttpErrorMessage): boolean =>
            msg.status === HttpStatus.ABORTED && isAdBlockOn(),
        getMessage: () =>
            "The HTTP request can't be resolved. It seems you are running AdBlock which could be " +
            "the reason for this issue. Please, disable AdBlock for this page and try again.",
    },
    {
        test: (msg: IHttpErrorMessage): boolean =>
            msg.errorCode === "QUOTA_EXCEEDED",
        getMessage: () =>
            "You have reached the limit of active items of this type. Please, contact the support to increase the limit.",
    },
    {
        test: () => true,
        getMessage: () =>
            "A front end error has occurred. Please, try again in a moment. " +
            "Don't worry, your campaigns will continue to run as always.",
    },
];

export interface IHttpErrorMessage {
    status: number;
    errorCode: string;
    body?: Record<string, unknown>;
    url?: string;
}

function createNotification(error: IHttpErrorMessage): IToastNotification {
    return {
        subType: NotificationSubtypeEnum.ERROR,
        message: generateErrorMessage(error),
        closeDelay: 10000,
    };
}

function generateErrorMessage(error: IHttpErrorMessage): string {
    return (
        CUSTOM_MESSAGES.find((customMessage: IMessageMapper) =>
            customMessage.test(error)
        )?.getMessage() || "Unknown error has occurred."
    );
}

/**
 * Determines if error should be handled on global level (in this handler, as opposed to handling error locally in
 * micro-app that caused the error).
 * Note that error handling will be re-designed in this task: https://codewise.myjetbrains.com/youtrack/issue/FET-544/Analysis-Error-handling
 */
function isHandledError(error: IHttpErrorMessage): boolean {
    const shouldIgnoreErrorBasedOnStatus =
        NOT_HANDLED_ERROR_STATUSES.includes(error.status) === true;
    const shouldIgnoreErrorBasedOnErrorCode =
        NOT_HANDLED_ERROR_CODES.includes(error.errorCode) === true;

    return !(
        shouldIgnoreErrorBasedOnStatus || shouldIgnoreErrorBasedOnErrorCode
    );
}

function convertToHttpErrorMessage(response: {
    status?: number;
    statusCode?: number;
    url: string;
    error?: { error?: { code: string } };
}): IHttpErrorMessage {
    // TODO: remove when YUI is gone
    const status = response.hasOwnProperty("status")
        ? (response.status as number)
        : (response.statusCode as number);

    let errorCode = "";
    let body: Record<string, unknown> | undefined = undefined;
    const url: string = response.url;

    if (response.error && response.error.error) {
        errorCode = response.error.error.code;
        body = response.error.error;
    }
    return { status, errorCode, body, url };
}

function isAdBlockOn(): boolean {
    const testAdBlockElement: HTMLElement = document.createElement("div");
    testAdBlockElement.innerHTML = "&nbsp;";
    testAdBlockElement.className = "adsbox";
    document.body.appendChild(testAdBlockElement);

    const _isAdBlockOn: boolean = testAdBlockElement.offsetHeight === 0;
    document.body.removeChild(testAdBlockElement);

    return _isAdBlockOn;
}

interface IMessageMapper {
    test: (msg: IHttpErrorMessage) => boolean;
    getMessage: () => string;
}
