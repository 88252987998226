import { IAppConfig } from "@codewise/voluum-frontend-core/app";
import { IPushNotifications } from "@voluum-panel-shared/push-notification-data-access";

import { EventBus } from "../../../service/event_bus";
import { IncomingMessagesRepository } from "../repository/incoming-messages";
import { NotificationActionDispatcher } from "./actions/dispatcher";

export class PushNotifications implements IPushNotifications {
    public incomingMessages?: IncomingMessagesRepository;

    constructor(
        private readonly appConfig: IAppConfig,
        private readonly eventBus: EventBus
    ) {}

    public async initialize(): Promise<void> {
        this.incomingMessages = new IncomingMessagesRepository(
            this.appConfig,
            this.eventBus
        );

        new NotificationActionDispatcher(this.eventBus, window.location);
    }
}
