import { AutoSerialize } from "@codewise/voluum-frontend-framework/repository";

export class OnboardingChecklistCardModel {
    @AutoSerialize()
    public checked: boolean = false;

    @AutoSerialize()
    public hidden: boolean = false;

    @AutoSerialize()
    public selectedOption?: string;
}
