import { IAppConfig } from "@codewise/voluum-frontend-core/app";
import { Api } from "@codewise/voluum-frontend-framework/api";
import { Serializer } from "@codewise/voluum-frontend-framework/repository";
import {
    Feedback,
    Message,
} from "@voluum-panel-shared/push-notification-data-access";
import { Observable } from "rxjs";
import { mapTo, tap } from "rxjs/operators";

import { EventBusWiredRepository, IEventBus } from "./event-bus-wired";

export class FeedbackRepository extends EventBusWiredRepository<Feedback> {
    constructor(appConfig: IAppConfig, eventBus: IEventBus, api?: Api) {
        super(
            eventBus,
            new Serializer(Feedback),
            {
                create: {
                    method: "POST",
                    url: `${appConfig.server.portal}/notification/feedback/\${messageId}`,
                },
            },
            {},
            api
        );
    }

    public storeFeedback(
        message: Message,
        feedback: Feedback
    ): Observable<Message> {
        return this.create(feedback, {
            pathParams: {
                messageId: message.id,
            },
        }).pipe(
            // FIXME: consider immutable changes
            tap((savedFeedback) => (message.feedback = savedFeedback)),
            mapTo(message)
        );
    }
}
