import { AutoSerialize } from "@codewise/voluum-frontend-framework/repository";

import { OnboardingChecklistCardModel } from "../onboarding-checklist-card.model";

export class OnboardingChecklistAccountCardsModel {
    @AutoSerialize()
    public customDomain: OnboardingChecklistCardModel =
        new OnboardingChecklistCardModel();
    @AutoSerialize()
    public workspaces: OnboardingChecklistCardModel =
        new OnboardingChecklistCardModel();
    @AutoSerialize()
    public members: OnboardingChecklistCardModel =
        new OnboardingChecklistCardModel();
}
