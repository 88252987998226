import {
    Format,
    Required,
} from "@codewise/voluum-frontend-framework/validation";

export class Mfa {
    @Required()
    @Format({
        regexp: /^[0-9]{6}$/,
        formatName: "six-digit code",
        message: "Six-digit code has invalid format",
    })
    public mfaCode: string | undefined;

    constructor(mfaCode?: string) {
        this.mfaCode = mfaCode;
    }
}
