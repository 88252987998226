import {
    AutoSerialize,
    AutoSerializeCollection,
} from "@codewise/voluum-frontend-framework/repository";

import { ChartPreferences } from "./chart-preferences.model";
import { CampaignTabEnum } from "./enum/campaign-tab.enum";
import { FeatureEnabled } from "./enum/feature-enabled.enum";
import { FeatureShownEnum } from "./enum/feature-shown.enum";
import { ReportPreferenceModel } from "./report-preference.model";
import { UserPreferenceBookmarksModel } from "./user-preference-bookmarks.model";

/**
 * @see ReportPreferenceModel
 * @see CampaignTabEnum
 * @see FeatureShownEnum
 * @description add new value/model consistent with user preference, if preference exist and not here
 *
 */
export class UserPreferenceModel {
    @AutoSerialize()
    public reportPreference: ReportPreferenceModel =
        new ReportPreferenceModel();

    @AutoSerialize("chartPreference")
    public chartPreferences: ChartPreferences = new ChartPreferences();

    @AutoSerialize()
    public newsFeedLastSeen: string = "";

    @AutoSerialize()
    public featuresShown: string[] = [];

    @AutoSerialize({ circuitBreaker: () => String })
    public defaultCampaignTab: CampaignTabEnum = CampaignTabEnum.GENERAL;

    @AutoSerialize()
    public enabledFeatures: FeatureEnabled[] = [];

    @AutoSerialize()
    public featuresRequested: string[] = [];

    @AutoSerializeCollection(String)
    public groupings: string[] = [];

    @AutoSerialize()
    public bookmarks: UserPreferenceBookmarksModel =
        new UserPreferenceBookmarksModel();

    public hasFeatureEnabled(feature: FeatureEnabled): boolean {
        return this.enabledFeatures.includes(feature);
    }

    public isInFeatureShown(feature: FeatureShownEnum): boolean {
        return this.featuresShown.includes(feature);
    }

    public addFeatureShown(feature: FeatureShownEnum): string[] {
        if (!this.isInFeatureShown(feature)) {
            this.featuresShown.push(feature);
        }
        return this.featuresShown;
    }

    public removeFeatureShown(feature: FeatureShownEnum): string[] {
        if (this.isInFeatureShown(feature)) {
            this.featuresShown.splice(this.featuresShown.indexOf(feature), 1);
        }
        return this.featuresShown;
    }

    get basicFormEnabled(): boolean {
        return !this.enabledFeatures.includes(
            FeatureEnabled.BASIC_FORM_DISABLED_TOGGLE
        );
    }

    get onboardingChecklistEnabled(): boolean {
        return !this.enabledFeatures.includes(
            FeatureEnabled.ONBOARDING_CHECKLIST_DISABLED_TOGGLE
        );
    }

    set basicFormEnabled(enabled: boolean) {
        // we use disabled key to have integrate-account enabled by default (and push 'disabled' key only when user requests it)
        const toggleDisabledIdx: number = this.enabledFeatures.indexOf(
            FeatureEnabled.BASIC_FORM_DISABLED_TOGGLE
        );
        if (enabled) {
            if (toggleDisabledIdx !== -1) {
                this.enabledFeatures.splice(toggleDisabledIdx, 1);
            }
        } else {
            if (toggleDisabledIdx === -1) {
                this.enabledFeatures.push(
                    FeatureEnabled.BASIC_FORM_DISABLED_TOGGLE
                );
            }
        }
    }

    set onboardingChecklistEnabled(enabled: boolean) {
        const toggleDisabledIdx: number = this.enabledFeatures.indexOf(
            FeatureEnabled.ONBOARDING_CHECKLIST_DISABLED_TOGGLE
        );
        if (enabled) {
            if (toggleDisabledIdx !== -1) {
                this.enabledFeatures.splice(toggleDisabledIdx, 1);
            }
        } else {
            if (toggleDisabledIdx === -1) {
                this.enabledFeatures.push(
                    FeatureEnabled.ONBOARDING_CHECKLIST_DISABLED_TOGGLE
                );
            }
        }
    }
}
