var _a, _b, _c, _d, _e, _f, _g;
import { __decorate, __metadata } from "tslib";
import { AutoSerialize } from "@codewise/voluum-frontend-framework/repository";
import { OnboardingChecklistCardModel } from "../onboarding-checklist-card.model";
export class OnboardingChecklistBasicsCardsModel {
    constructor() {
        this.createCampaign = new OnboardingChecklistCardModel();
        this.connectClickUrl = new OnboardingChecklistCardModel();
        this.conversionTracking = new OnboardingChecklistCardModel();
        this.submitCampaignUrl = new OnboardingChecklistCardModel();
        this.trackVisit = new OnboardingChecklistCardModel();
        this.trackClick = new OnboardingChecklistCardModel();
        this.trackConversion = new OnboardingChecklistCardModel();
    }
}
__decorate([
    AutoSerialize(),
    __metadata("design:type", typeof (_a = typeof OnboardingChecklistCardModel !== "undefined" && OnboardingChecklistCardModel) === "function" ? _a : Object)
], OnboardingChecklistBasicsCardsModel.prototype, "createCampaign", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", typeof (_b = typeof OnboardingChecklistCardModel !== "undefined" && OnboardingChecklistCardModel) === "function" ? _b : Object)
], OnboardingChecklistBasicsCardsModel.prototype, "connectClickUrl", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", typeof (_c = typeof OnboardingChecklistCardModel !== "undefined" && OnboardingChecklistCardModel) === "function" ? _c : Object)
], OnboardingChecklistBasicsCardsModel.prototype, "conversionTracking", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", typeof (_d = typeof OnboardingChecklistCardModel !== "undefined" && OnboardingChecklistCardModel) === "function" ? _d : Object)
], OnboardingChecklistBasicsCardsModel.prototype, "submitCampaignUrl", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", typeof (_e = typeof OnboardingChecklistCardModel !== "undefined" && OnboardingChecklistCardModel) === "function" ? _e : Object)
], OnboardingChecklistBasicsCardsModel.prototype, "trackVisit", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", typeof (_f = typeof OnboardingChecklistCardModel !== "undefined" && OnboardingChecklistCardModel) === "function" ? _f : Object)
], OnboardingChecklistBasicsCardsModel.prototype, "trackClick", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", typeof (_g = typeof OnboardingChecklistCardModel !== "undefined" && OnboardingChecklistCardModel) === "function" ? _g : Object)
], OnboardingChecklistBasicsCardsModel.prototype, "trackConversion", void 0);
