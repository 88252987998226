import { IAppConfig } from "@codewise/voluum-frontend-core/app";
import { IFirstViewSurvey } from "@voluum-panel/first-view-survey-facade";
import { IClientBasicInfoJson } from "@voluum-panel/profile-facade";
import { ICouponsJson } from "@voluum-panel/subscription-facade";
import { type IPlanJson } from "@voluum-panel-shared/plan-data-access";
import { type ColumnsOrderTemplate } from "@voluum-panel-shared/preferences-data-access";
import {
    type IUserJSON,
    type MultiuserMigrationStatus,
} from "@voluum-panel-shared/profile-data-access";
import { TimeZone } from "@voluum-panel-shared/time-data-access";
import { Observable } from "rxjs";
import { ajax } from "rxjs/ajax";
import { map, pluck } from "rxjs/operators";

import {
    AUTHENTICATION_HEADER_NAME,
    AUTHENTICATION_PANEL_HEADER_NAME,
    CLIENT_ID_QUERY_PARAM_NAME,
} from "../globals";
import { IFeatures } from "../module/session/service/manager";
import { ISessionStatus } from "../module/session/service/monitor";
import { IWorkspaceResponse } from "../module/workspaces/service/workspaces";
import { Authentication, encodeToken } from "./authentication";
import { IInvitation, Invitation } from "./invitation";

export class Startup {
    private readonly startupEndpoint: string;

    constructor(appConfig: IAppConfig, invitation: Invitation) {
        this.startupEndpoint = `${appConfig.server.portal}/startup`;
        if (invitation.invitationToken) {
            this.startupEndpoint += `?invitationId=${encodeURIComponent(
                invitation.invitationToken
            )}`;
        }
    }

    public load(clientId: string): Observable<IStartupResponse> {
        const headers: Record<string, string> = {
            [AUTHENTICATION_HEADER_NAME]: Authentication.getToken(),
            [AUTHENTICATION_PANEL_HEADER_NAME]: encodeToken(
                Authentication.getToken()
            ),
            [CLIENT_ID_QUERY_PARAM_NAME]: clientId,
        };

        return ajax.get(this.startupEndpoint, headers).pipe(
            pluck("response", "responses"),
            map((startupResponse: IStartupResponse) => {
                if (
                    Object.values(startupResponse).some(
                        (response) => response == null
                    )
                ) {
                    throw new Error("FATAL");
                }
                return startupResponse;
            })
        );
    }
}

export interface IStartupResponse {
    user: IResponse<IUserJSON>;
    session: IResponse<ISessionStatus>;
    invitation: IResponse<IInvitation>;
    plan: IResponse<IPlanJson>;
    coupon: IResponse<ICouponsJson>;
    multiUser: IResponse<{ status: MultiuserMigrationStatus }>;
    zpIntegration: IResponse<unknown>;
    notification: IResponse<object>;
    customConversions: IResponse<unknown>;
    customColumns: IResponse<unknown>;
    features: IResponse<IFeatures>;
    workspaces: IResponse<IWorkspaceResponse>;
    preferences: IResponse<object>;
    promoSummary: IResponse<object>;
    entityQuota: IResponse<object>;
    billingInfo: IResponse<object>;
    firstViewSurvey: IResponse<IFirstViewSurvey | null>;
    timezones: IResponse<{ timezones: TimeZone[] }>;
    columnOrderTemplates: IResponse<ColumnsOrderTemplate[]>;
    clientBasicInfo: IResponse<IClientBasicInfoJson>;
    onboardingChecklist: IResponse<object>;
}

export interface IResponse<T> {
    body: T;
    headers: {
        [headerName: string]: string;
    };
    status: number;
    statusText: string;
}
