import { IAppConfig } from "@codewise/voluum-frontend-core/app";
import { Plan } from "@voluum-panel-shared/plan-data-access";
import { UserPreferenceModel } from "@voluum-panel-shared/preferences-data-access";
import { Session } from "@voluum-panel-shared/profile-data-access";
import { PromoSummary } from "@voluum-panel-shared/promo-data-access";

import { EventBus } from "../../service/event_bus";
import { TagManager } from "./app/tag_manager";
import { Userflow } from "./app/userflow";

export class Service {
    private analyticsKeysMapping: Record<string, string> = {
        tagManager: "tagManagerID",
    };

    constructor(
        private appConfig: IAppConfig,
        private eventBus: EventBus
    ) {}

    public initialize(
        session: Session,
        plan: Plan,
        preferences: UserPreferenceModel,
        promoSummary: PromoSummary
    ): void {
        for (const type in this.analyticsKeysMapping) {
            if (this.analyticsKeysMapping.hasOwnProperty(type)) {
                const key: string =
                    this.appConfig.analytics[this.analyticsKeysMapping[type]];
                if (typeof key === "string" && key.length) {
                    this.createApp(
                        type,
                        key,
                        this.eventBus,
                        session,
                        plan,
                        preferences,
                        promoSummary
                    );
                }
            }
        }

        new Userflow(this.eventBus);
    }

    private createApp(
        type: string,
        key: string,
        eventBus: EventBus,
        session: Session,
        plan: Plan,
        preferences: UserPreferenceModel,
        promoSummary: PromoSummary
    ): unknown {
        switch (type) {
            case "tagManager":
                return new TagManager(
                    key,
                    eventBus,
                    session,
                    plan,
                    promoSummary,
                    preferences
                );
            default:
                throw new Error(`Unknown tracking app ${type}`);
        }
    }
}
