var _a, _b, _c;
import { __decorate, __metadata } from "tslib";
import { AutoSerialize } from "@codewise/voluum-frontend-framework/repository";
import { OnboardingChecklistEventModel } from "../onboarding-checklist-event.model";
export class OnboardingChecklistBasicsEventsModel {
    constructor() {
        this.conversion = new OnboardingChecklistEventModel();
        this.click = new OnboardingChecklistEventModel();
        this.visit = new OnboardingChecklistEventModel();
    }
}
__decorate([
    AutoSerialize(),
    __metadata("design:type", typeof (_a = typeof OnboardingChecklistEventModel !== "undefined" && OnboardingChecklistEventModel) === "function" ? _a : Object)
], OnboardingChecklistBasicsEventsModel.prototype, "conversion", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", typeof (_b = typeof OnboardingChecklistEventModel !== "undefined" && OnboardingChecklistEventModel) === "function" ? _b : Object)
], OnboardingChecklistBasicsEventsModel.prototype, "click", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", typeof (_c = typeof OnboardingChecklistEventModel !== "undefined" && OnboardingChecklistEventModel) === "function" ? _c : Object)
], OnboardingChecklistBasicsEventsModel.prototype, "visit", void 0);
