import {
    NotificationActionCommand,
    NotificationActionCommandType,
} from "@voluum-panel-shared/push-notification-data-access";

export interface IActionPattern {
    ShowCampaign: (campaignId: string) => NotificationActionCommand;
    ShowDomainSettings: (domain: string) => NotificationActionCommand;
    ShowOffer: (offerId: string) => NotificationActionCommand;
    ShowBillingPage: () => NotificationActionCommand;
    ShowInboxPage: () => NotificationActionCommand;
    OpenPaymentMethodPopup: () => NotificationActionCommand;
    OpenUpgradeAccountPopupOnAnnualTab: () => NotificationActionCommand;
}

export const actionPattern: IActionPattern = {
    ShowCampaign: (campaignName: string) => ({
        type: NotificationActionCommandType.EVENT,
        payload: {
            eventName: "navigateToEntityReportSearchedById",
            eventData: {
                entityId: campaignName,
                entityGrouping: "campaign",
            },
        },
    }),
    ShowDomainSettings: () => ({
        type: NotificationActionCommandType.NAVIGATION,
        payload: {
            hash: "/settings/domains",
        },
    }),
    ShowOffer: (offerName: string) => ({
        type: NotificationActionCommandType.EVENT,
        payload: {
            eventName: "navigateToEntityReportSearchedById",
            eventData: {
                entityId: offerName,
                entityGrouping: "offer",
            },
        },
    }),
    ShowBillingPage: () => ({
        type: NotificationActionCommandType.NAVIGATION,
        payload: {
            hash: "/settings/billing",
        },
    }),
    ShowInboxPage: () => ({
        type: NotificationActionCommandType.NAVIGATION,
        payload: {
            hash: "/notifications/inbox",
        },
    }),
    OpenPaymentMethodPopup: () => ({
        type: NotificationActionCommandType.EVENT,
        payload: {
            eventName: "changePaymentMethod",
        },
    }),
    OpenUpgradeAccountPopupOnAnnualTab: () => ({
        type: NotificationActionCommandType.EVENT,
        payload: {
            eventName: "changePlan",
            eventData: { preselectedTimePeriod: "1_YEAR" },
        },
    }),
};
