// re-export models and data that is used in tests and ring (to prevent being dependent on Angular)
export {
    type IClientBasicInfoJson,
    ClientBasicInfoModel,
} from "@voluum-panel/profile-data-access";

export { type IAuthenticationTokenChangedEvent } from "@voluum-panel/profile-data-access";
export { type ILoginFailureEvent } from "@voluum-panel/profile-data-access";
export { type ILoginSuccessfulEvent } from "@voluum-panel/profile-data-access";
export { type IReloginEvent } from "@voluum-panel/profile-data-access";
export { type IReloginWithGoogleEvent } from "@voluum-panel/profile-data-access";
export { type IReloginWithMfaEvent } from "@voluum-panel/profile-data-access";
export { type IShowLoginFormEvent } from "@voluum-panel/profile-data-access";
export { type IShowMFAFormEvent } from "@voluum-panel/profile-data-access";

export { Login } from "@voluum-panel/profile-data-access";
export { type LoginWithGoogle } from "@voluum-panel/profile-data-access";
export { type LoginWithMfa } from "@voluum-panel/profile-data-access";
