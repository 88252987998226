import { EventName } from "@codewise/voluum-frontend-core/events";
import {
    IUserFlowEndedEvent,
    IUserFlowEndEvent,
} from "@voluum-panel-shared/general-events-data-access";

import { EventBus } from "../../../service/event_bus";

export class Userflow {
    private context: Window & {
        userflow?: {
            on: (event: string, cb: (event: unknown) => void) => void;
        };
    };

    constructor(private eventBus: EventBus) {
        this.context = window;
        this.listenToFlowEnd();
    }

    private listenToFlowEnd() {
        this.eventBus.onAllReady.subscribe(() => {
            if (!this.context.userflow) return;
            this.context.userflow.on("flowEnded", (e: IUserFlowEndEvent) => {
                this.eventBus.dispatch<IUserFlowEndedEvent>({
                    eventName: EventName.USER_FLOW_ENDED,
                    data: e,
                });
            });
        });
    }
}
