var _a, _b, _c;
import { __decorate, __metadata } from "tslib";
import { AutoSerialize } from "@codewise/voluum-frontend-framework/repository";
import { OnboardingChecklistCardModel } from "../onboarding-checklist-card.model";
export class OnboardingChecklistAdvancedCardsModel {
    constructor() {
        this.customConversion = new OnboardingChecklistCardModel();
        this.integration = new OnboardingChecklistCardModel();
        this.tsPostback = new OnboardingChecklistCardModel();
    }
}
__decorate([
    AutoSerialize(),
    __metadata("design:type", typeof (_a = typeof OnboardingChecklistCardModel !== "undefined" && OnboardingChecklistCardModel) === "function" ? _a : Object)
], OnboardingChecklistAdvancedCardsModel.prototype, "customConversion", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", typeof (_b = typeof OnboardingChecklistCardModel !== "undefined" && OnboardingChecklistCardModel) === "function" ? _b : Object)
], OnboardingChecklistAdvancedCardsModel.prototype, "integration", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", typeof (_c = typeof OnboardingChecklistCardModel !== "undefined" && OnboardingChecklistCardModel) === "function" ? _c : Object)
], OnboardingChecklistAdvancedCardsModel.prototype, "tsPostback", void 0);
