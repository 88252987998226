import { __decorate, __metadata } from "tslib";
import { Format, Required, } from "@codewise/voluum-frontend-framework/validation";
export class Mfa {
    constructor(mfaCode) {
        this.mfaCode = mfaCode;
    }
}
__decorate([
    Required(),
    Format({
        regexp: /^[0-9]{6}$/,
        formatName: "six-digit code",
        message: "Six-digit code has invalid format",
    }),
    __metadata("design:type", String)
], Mfa.prototype, "mfaCode", void 0);
