import { NotificationActionCommandType } from "@voluum-panel-shared/push-notification-data-access";
import { map } from "rxjs/operators";

import { EventBus } from "../../../../service/event_bus";
import { actionMatcher } from "./matcher";
import { actionPattern } from "./pattern";

export class NotificationActionDispatcher {
    constructor(eventBus: EventBus, location: Location) {
        eventBus.onPerformNotificationAction
            .pipe(map(actionMatcher(actionPattern)))
            .subscribe((command) => {
                switch (command.type) {
                    case NotificationActionCommandType.EVENT:
                        eventBus.sendEvent(
                            command.payload.eventName,
                            command.payload.eventData
                        );
                        break;

                    case NotificationActionCommandType.NAVIGATION:
                        location.hash = command.payload.hash;
                        break;

                    default:
                        return;
                }
            });
    }
}
