// re-export models and data that is used in tests and ring (to prevent being dependent on Angular)
export {
    type IClientBasicInfoJson,
    ClientBasicInfoModel,
} from "./src/lib/client/model/client-basic-info.model";

export { type IAuthenticationTokenChangedEvent } from "./src/lib/events/authentication-token-changed.event";
export { type ILoginFailureEvent } from "./src/lib/events/login-failure.event";
export { type ILoginSuccessfulEvent } from "./src/lib/events//login-successful.event";
export { type IReloginEvent } from "./src/lib/events//relogin.event";
export { type IReloginWithGoogleEvent } from "./src/lib/events/relogin-with-google.event";
export { type IReloginWithMfaEvent } from "./src/lib/events/relogin-with-mfa.event";
export { type IShowLoginFormEvent } from "./src/lib/events/show-login-form.event";
export { type IShowMFAFormEvent } from "./src/lib/events/show-mfa-form.event";

export { Login } from "./src/lib/login/model/login.model";
export { Mfa } from "./src/lib/login/model/mfa.model";
export { type LoginWithGoogle } from "./src/lib/login/model/login-with-google.model";
export { type LoginWithMfa } from "./src/lib/login/model/login-with-mfa.model";
