import { AutoSerialize } from "@codewise/voluum-frontend-framework/repository";

import { OnboardingChecklistCardModel } from "../onboarding-checklist-card.model";

export class OnboardingChecklistAdvancedCardsModel {
    @AutoSerialize()
    public customConversion: OnboardingChecklistCardModel =
        new OnboardingChecklistCardModel();
    @AutoSerialize()
    public integration: OnboardingChecklistCardModel =
        new OnboardingChecklistCardModel();
    @AutoSerialize()
    public tsPostback: OnboardingChecklistCardModel =
        new OnboardingChecklistCardModel();
}
