import { Client } from "../client/client.model";
export class Membership {
    static deserializer(membershipData) {
        var _a;
        membershipData.client.role = membershipData.role;
        return new Membership(membershipData.role, Client.deserializer(membershipData.client), membershipData.restrictedColumns, (_a = membershipData.grantedPermissions) !== null && _a !== void 0 ? _a : []);
    }
    constructor(_role, _client, _restrictedColumns, _permissions = []) {
        this._role = _role;
        this._client = _client;
        this._restrictedColumns = _restrictedColumns;
        this._permissions = _permissions;
    }
    get role() {
        return this._role;
    }
    get client() {
        return this._client;
    }
    get restrictedColumns() {
        return this._restrictedColumns;
    }
    get permissions() {
        return this._permissions;
    }
    hasPermission(permissionName) {
        const isOwner = this._role === "OWNER";
        return isOwner || this.permissions.includes(permissionName);
    }
    hasRole(role) {
        return this._role === role;
    }
}
