import { Client, IClientJSON } from "../client/client.model";
import { PermissionName } from "../permission";
import { type MembershipRole } from "./membership-role.type";

export interface IRestrictedColumns {
    name: string;
}

export interface IMembershipJSON {
    role: MembershipRole;
    client: IClientJSON;
    restrictedColumns: IRestrictedColumns[];
    grantedPermissions: PermissionName[];
}

export class Membership {
    public static deserializer(membershipData: IMembershipJSON): Membership {
        membershipData.client.role = membershipData.role;
        return new Membership(
            membershipData.role,
            Client.deserializer(membershipData.client),
            membershipData.restrictedColumns,
            membershipData.grantedPermissions ?? []
        );
    }

    constructor(
        private _role: MembershipRole,
        private _client: Client,
        private _restrictedColumns: IRestrictedColumns[],
        private _permissions: PermissionName[] = []
    ) {}

    get role(): MembershipRole {
        return this._role;
    }

    get client(): Client {
        return this._client;
    }

    get restrictedColumns(): IRestrictedColumns[] {
        return this._restrictedColumns;
    }

    get permissions(): PermissionName[] {
        return this._permissions;
    }

    /**
     * Check if the given user under given client (in given membership) has a specific permission
     * @param permissionName the name of the permission to check
     * @returns true if the membership has the permission, false otherwise
     */
    public hasPermission(permissionName: PermissionName): boolean {
        const isOwner = this._role === "OWNER";

        // If the user is the OWNER, we assume that they have all permissions.
        // This logic is added, because on BE side OWNERS are treated differently, and they do NOT have permissions
        // associated with them. Because of this, for memberships with OWNER role, empty array of permissions
        // is returned.
        // If the user is NOT the OWNER, we check if the permission is in the list of granted permissions.
        return isOwner || this.permissions.includes(permissionName);
    }

    /**
     * Check if the given user under given client (in given membership) has a specific role.
     * @param role the name of the role to check
     * @returns true if the membership has the role, false otherwise
     */
    public hasRole(role: MembershipRole): boolean {
        return this._role === role;
    }
}
