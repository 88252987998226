import { AutoSerialize } from "@codewise/voluum-frontend-framework/repository";

import { OnboardingChecklistBasicsCardsModel } from "./onboarding-checklist-basics-cards.model";
import { OnboardingChecklistBasicsEventsModel } from "./onboarding-checklist-basics-events.model";

export class OnboardingChecklistBasicsModel {
    @AutoSerialize()
    public cards: OnboardingChecklistBasicsCardsModel =
        new OnboardingChecklistBasicsCardsModel();

    @AutoSerialize()
    public events: OnboardingChecklistBasicsEventsModel =
        new OnboardingChecklistBasicsEventsModel();
}
