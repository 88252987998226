import { type MultiuserMigrationStatus } from "./multiuser-migration-status.type";

export class MultiuserMigration {
    get status(): MultiuserMigrationStatus {
        return this._status;
    }

    public static deserializer(data: {
        status: MultiuserMigrationStatus;
    }): MultiuserMigration {
        return new MultiuserMigration(data.status);
    }

    constructor(private _status: MultiuserMigrationStatus) {}
}
