import { AutoSerialize } from "@codewise/voluum-frontend-framework/repository";

import { OnboardingChecklistCardModel } from "../onboarding-checklist-card.model";

export class OnboardingChecklistBasicsCardsModel {
    @AutoSerialize()
    public createCampaign: OnboardingChecklistCardModel =
        new OnboardingChecklistCardModel();
    @AutoSerialize()
    public connectClickUrl: OnboardingChecklistCardModel =
        new OnboardingChecklistCardModel();
    @AutoSerialize()
    public conversionTracking: OnboardingChecklistCardModel =
        new OnboardingChecklistCardModel();

    @AutoSerialize()
    public submitCampaignUrl: OnboardingChecklistCardModel =
        new OnboardingChecklistCardModel();
    @AutoSerialize()
    public trackVisit: OnboardingChecklistCardModel =
        new OnboardingChecklistCardModel();
    @AutoSerialize()
    public trackClick: OnboardingChecklistCardModel =
        new OnboardingChecklistCardModel();
    @AutoSerialize()
    public trackConversion: OnboardingChecklistCardModel =
        new OnboardingChecklistCardModel();
}
