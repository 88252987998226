var _a;
import { __decorate, __metadata } from "tslib";
import { AutoSerialize } from "@codewise/voluum-frontend-framework/repository";
import { OnboardingChecklistAdvancedCardsModel } from "./onboarding-checklist-advanced-cards.model";
export class OnboardingChecklistAdvancedModel {
    constructor() {
        this.cards = new OnboardingChecklistAdvancedCardsModel();
    }
}
__decorate([
    AutoSerialize(),
    __metadata("design:type", typeof (_a = typeof OnboardingChecklistAdvancedCardsModel !== "undefined" && OnboardingChecklistAdvancedCardsModel) === "function" ? _a : Object)
], OnboardingChecklistAdvancedModel.prototype, "cards", void 0);
