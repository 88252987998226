import {
    MultiuserMigration,
    MultiuserMigrationStatus,
} from "@voluum-panel-shared/profile-data-access";

import { IResponse } from "../../../service/startup";
import { SessionManager } from "../../session/service/manager";

export class MultiuserStatusManager {
    constructor(private sessionManager: SessionManager) {}

    public parseResponse(
        multiuserResponse: IResponse<{ status: MultiuserMigrationStatus }>
    ): void {
        let status: MultiuserMigrationStatus = "UNAVAILABLE";
        if (multiuserResponse.status === 200) {
            status = MultiuserMigration.deserializer(
                multiuserResponse.body
            ).status;
        }
        this.sessionManager.setMultiuserMigratinoStatus(status);
    }
}
