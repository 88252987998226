import { AutoSerialize } from "@codewise/voluum-frontend-framework/repository";

import { OnboardingChecklistEventModel } from "../onboarding-checklist-event.model";

export class OnboardingChecklistBasicsEventsModel {
    @AutoSerialize()
    public conversion: OnboardingChecklistEventModel =
        new OnboardingChecklistEventModel();
    @AutoSerialize()
    public click: OnboardingChecklistEventModel =
        new OnboardingChecklistEventModel();
    @AutoSerialize()
    public visit: OnboardingChecklistEventModel =
        new OnboardingChecklistEventModel();
}
