import { __decorate, __metadata } from "tslib";
import { AutoSerialize } from "@codewise/voluum-frontend-framework/repository";
export class OnboardingChecklistCardModel {
    constructor() {
        this.checked = false;
        this.hidden = false;
    }
}
__decorate([
    AutoSerialize(),
    __metadata("design:type", Boolean)
], OnboardingChecklistCardModel.prototype, "checked", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", Boolean)
], OnboardingChecklistCardModel.prototype, "hidden", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", String)
], OnboardingChecklistCardModel.prototype, "selectedOption", void 0);
