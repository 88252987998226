import {
    Format,
    Required,
} from "@codewise/voluum-frontend-framework/validation";

import { EMAIL_DOPPELGANG_REGEXP } from "../data/email-dopplegang.regexp";

export class Login {
    @Required()
    @Format({
        regexp: EMAIL_DOPPELGANG_REGEXP,
        formatName: "email",
    })
    public email: string | undefined;

    @Required()
    public password: string | undefined;
    public captchaToken: string | null | undefined;

    constructor(email?: string, password?: string) {
        this.email = email;
        this.password = password;
    }
}
