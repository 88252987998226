import { IAppConfig } from "@codewise/voluum-frontend-core/app";
import { type IUserJSON, User } from "@voluum-panel-shared/profile-data-access";
import { Observable, of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { map, pluck, tap } from "rxjs/operators";

import {
    AUTHENTICATION_HEADER_NAME,
    AUTHENTICATION_PANEL_HEADER_NAME,
    CACHED_INDEX_VERSION_PARAM,
    CLIENT_ID_QUERY_PARAM_NAME,
} from "../globals";
import { Authentication, encodeToken } from "./authentication";
import { Url } from "./url";

const uuidRegexp =
    /^([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})$/;

export class ClientId {
    private readonly profileEndpoint: string = `${this.appConfig.server.portal}/profile`;

    constructor(
        private appConfig: IAppConfig,
        private readonly url: Url
    ) {}

    public retrieveClientId(): Observable<string> {
        if (
            this.url.has(CLIENT_ID_QUERY_PARAM_NAME) &&
            uuidRegexp.test(this.url.get(CLIENT_ID_QUERY_PARAM_NAME)!)
        ) {
            const clientId: string = this.url.get(CLIENT_ID_QUERY_PARAM_NAME)!;

            return of(clientId);
        }

        return ajax
            .get(this.profileEndpoint, {
                [AUTHENTICATION_HEADER_NAME]: Authentication.getToken(),
                [AUTHENTICATION_PANEL_HEADER_NAME]: encodeToken(
                    Authentication.getToken()
                ),
            })
            .pipe(
                pluck("response"),
                map((userData: IUserJSON) => User.deserializer(userData)),
                map((user: User) => user.defaultClient.id),
                tap((clientId: string) => this.appendClientIdToUrl(clientId))
            );
    }

    private appendClientIdToUrl(clientId: string): void {
        const url = new URL(window.location.href);

        // append client ID to the url as a query parameter
        url.searchParams.append(CLIENT_ID_QUERY_PARAM_NAME, clientId);

        // Remove potentially existent "index.html version param", used to forcefully fetch new index.html
        // (this is needed for scenario when user has old index.html cached in the browser - which generally
        //  should NOT happen, but due to deployment rewrite it might till end of April 2025)
        // See: https://codewise.myjetbrains.com/youtrack/issue/V-5786/Mitigate-cached-index.html-impact
        url.searchParams.delete(CACHED_INDEX_VERSION_PARAM);

        window.history.pushState({ path: url.toString() }, "", url.toString());
    }
}
