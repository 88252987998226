var _a, _b;
import { __decorate, __metadata } from "tslib";
import { AutoSerialize } from "@codewise/voluum-frontend-framework/repository";
import { OnboardingChecklistBasicsCardsModel } from "./onboarding-checklist-basics-cards.model";
import { OnboardingChecklistBasicsEventsModel } from "./onboarding-checklist-basics-events.model";
export class OnboardingChecklistBasicsModel {
    constructor() {
        this.cards = new OnboardingChecklistBasicsCardsModel();
        this.events = new OnboardingChecklistBasicsEventsModel();
    }
}
__decorate([
    AutoSerialize(),
    __metadata("design:type", typeof (_a = typeof OnboardingChecklistBasicsCardsModel !== "undefined" && OnboardingChecklistBasicsCardsModel) === "function" ? _a : Object)
], OnboardingChecklistBasicsModel.prototype, "cards", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", typeof (_b = typeof OnboardingChecklistBasicsEventsModel !== "undefined" && OnboardingChecklistBasicsEventsModel) === "function" ? _b : Object)
], OnboardingChecklistBasicsModel.prototype, "events", void 0);
