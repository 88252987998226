var _a, _b, _c;
import { __decorate, __metadata } from "tslib";
import { AutoSerialize } from "@codewise/voluum-frontend-framework/repository";
import { OnboardingChecklistCardModel } from "../onboarding-checklist-card.model";
export class OnboardingChecklistAccountCardsModel {
    constructor() {
        this.customDomain = new OnboardingChecklistCardModel();
        this.workspaces = new OnboardingChecklistCardModel();
        this.members = new OnboardingChecklistCardModel();
    }
}
__decorate([
    AutoSerialize(),
    __metadata("design:type", typeof (_a = typeof OnboardingChecklistCardModel !== "undefined" && OnboardingChecklistCardModel) === "function" ? _a : Object)
], OnboardingChecklistAccountCardsModel.prototype, "customDomain", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", typeof (_b = typeof OnboardingChecklistCardModel !== "undefined" && OnboardingChecklistCardModel) === "function" ? _b : Object)
], OnboardingChecklistAccountCardsModel.prototype, "workspaces", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", typeof (_c = typeof OnboardingChecklistCardModel !== "undefined" && OnboardingChecklistCardModel) === "function" ? _c : Object)
], OnboardingChecklistAccountCardsModel.prototype, "members", void 0);
