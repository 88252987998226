/**
 * List of possible features that given USER can have enabled. This is per-user
 * setting, sourced from user preferences, as opposed to FeatureToggle,
 * which is per-client and is sourced from internal tool that allows support team to manage toggles (LaunchDarkly)
 */
export enum FeatureEnabled {
    /**
     * User can enable "old navigation" (a.k.a. toolbar), if he wishes to.
     * The logic behind it is that our team can enable SIDENAV for client, but
     * specific user can disable SIDENAV for himself (actually, enable "old nav").
     */
    TOOLBAR_OLD_NAVIGATION = "toolbar-old-navigation",
    BASIC_FORM_DISABLED_TOGGLE = "basicFormDisabled",
    ONBOARDING_CHECKLIST_DISABLED_TOGGLE = "onboardingChecklistDisabled",
}
