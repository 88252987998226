import "zone.js";
import "reflect-metadata";
// import global styles for whole application
import "./global.scss";

import {
    BootstrapOptions,
    IAngularApp,
    IAppConfig,
    Time,
} from "@codewise/voluum-frontend-core/app";
import { EventBus as EventBusFactory } from "@codewise/voluum-frontend-framework/event_bus_client";
import { Serializer } from "@codewise/voluum-frontend-framework/repository";
import { CustomConversion } from "@voluum-panel/entities-facade";
import { IFirstViewSurvey } from "@voluum-panel/first-view-survey-facade";
import { OnboardingChecklistModel } from "@voluum-panel/onboarding-facade";
import { ClientBasicInfoModel } from "@voluum-panel/profile-facade";
import { CustomColumn } from "@voluum-panel/reports-data-access";
import {
    BillingInfo,
    Coupons,
    PaymentNotification,
} from "@voluum-panel/subscription-facade";
import { HttpStatus } from "@voluum-panel-shared/http-data-access";
import { Newsfeed } from "@voluum-panel-shared/newsfeed-data-access";
import { Plan, QuotaLimits } from "@voluum-panel-shared/plan-data-access";
import {
    ColumnsOrderTemplate,
    UserPreferenceModel,
} from "@voluum-panel-shared/preferences-data-access";
import { Session } from "@voluum-panel-shared/profile-data-access";
import { PromoSummary } from "@voluum-panel-shared/promo-data-access";
import { IPushNotifications } from "@voluum-panel-shared/push-notification-data-access";
import { TimeZone } from "@voluum-panel-shared/time-data-access";
import { IWorkspacesManager } from "@voluum-panel-shared/workspaces-data-access";
/// / expose moment as global variable ////
import * as moment from "moment-timezone";
import { forkJoin, map } from "rxjs";
import { AjaxError } from "rxjs/ajax";
import * as WebFont from "webfontloader";

import { FatalError as FatalErrorComponent } from "./component/fatal_error/fatal_error";
import { events } from "./events";
import { Freshdesk } from "./module/analytics/app/freshdesk";
import { Service as Analytics } from "./module/analytics/service";
import { Coupon as CouponManager } from "./module/coupon/manager/coupon";
import { MultiuserStatusManager } from "./module/multiuser/manager/multiuser";
import { PlanMonitor } from "./module/plan/service/monitor";
import { PlanManager } from "./module/plan/service/plan";
import { PushNotifications } from "./module/push-notifications/service/push-notifications";
import { SessionManager } from "./module/session/service/manager";
import { SessionMonitor } from "./module/session/service/monitor";
import { WorkspacesManager } from "./module/workspaces/service/workspaces";
import { AppLoadStrategyService } from "./service/app_load_strategy";
import { Apps } from "./service/apps";
import { Authentication } from "./service/authentication";
import { ClientId } from "./service/client_id";
import { Config } from "./service/config";
import { Datadog } from "./service/datadog";
import { DocumentationLinks } from "./service/doc_links";
import { Environment } from "./service/environment";
import { EventBus as EventBusService } from "./service/event_bus";
import { EventWatcher } from "./service/event_watcher";
import { HttpRequestErrorHandler } from "./service/http_request_error_handler";
import { Invitation } from "./service/invitation";
import { LoginHandler } from "./service/login-handler";
import { Navigation } from "./service/navigation";
import { NewsfeedService } from "./service/newsfeed";
import { Router } from "./service/router";
import { SideNavWidthService } from "./service/side-nav-width-service";
import { IStartupResponse, Startup } from "./service/startup";
import { Url } from "./service/url";
import { ZpToken } from "./service/zp_token";

window.moment = moment;

//////////////////////////////////////////
interface TypedBootstrapOptions extends BootstrapOptions {
    billingInfo: BillingInfo;
    columnOrderTemplates: ColumnsOrderTemplate[];
    coupons: Coupons;
    customColumns: CustomColumn[];
    customConversions: CustomConversion[];
    documentationLinks: unknown;
    isFirstLoad: boolean;
    newsfeed: Newsfeed;
    paymentNotification: PaymentNotification;
    plan: Plan;
    preferences: UserPreferenceModel;
    promoSummary: PromoSummary;
    pushNotifications: IPushNotifications;
    quotaLimits: QuotaLimits;
    session: Session;
    timezones: TimeZone[];
    workspacesManager: IWorkspacesManager;
    clientBasicInfo: ClientBasicInfoModel;
    firstViewSurvey: IFirstViewSurvey | null;
    onboardingChecklist: OnboardingChecklistModel | null;
}

class App {
    public eventWatcher?: EventWatcher;
    public appConfig?: IAppConfig;
    public startupResponse?: IStartupResponse;
    public eventBusService?: EventBusService;
    private APP_NAME: string = "ring";
    private invitation?: Invitation;
    private zpToken?: ZpToken;
    private planManager?: PlanManager;
    private couponManager?: CouponManager;
    private sessionManager?: SessionManager;
    private sessionMonitor?: SessionMonitor;
    private multiuserStatusManager?: MultiuserStatusManager;
    private eventBusFactory?: EventBusFactory;
    private environment?: Environment;
    private analytics?: Analytics;
    private time?: Time;
    private datadog?: Datadog;
    private startup?: Startup;
    private clientId?: ClientId;
    private readonly AUTHORIZATION_EXCEPTION: Error = new Error("UNAUTHORIZED");
    private documentationLinks?: unknown;
    private workspacesManager?: WorkspacesManager;
    private pushNotifications?: PushNotifications;
    private newsfeed?: Newsfeed;
    private preferences?: UserPreferenceModel;
    private promoSummary?: PromoSummary;
    private paymentNotification?: PaymentNotification;
    private billingInfo?: BillingInfo;
    private quotaLimits?: QuotaLimits;
    private customColumns: CustomColumn[] = [];
    private customConversion: CustomConversion[] = [];
    private timezones: TimeZone[] = [];
    private columnOrderTemplates: ColumnsOrderTemplate[] = [];
    private clientBasicInfo?: ClientBasicInfoModel;
    private firstViewSurvey: IFirstViewSurvey | null = null;
    private onboardingChecklist: OnboardingChecklistModel | null;

    public constructor() {
        this.preloadFonts();
    }

    public async run(): Promise<void> {
        try {
            // make sure that content won't jump when side navigation is bootstrapped
            SideNavWidthService.determineMode();

            const [appConfig, documentationLinks, newsfeed] = await Promise.all(
                [
                    Config.getAppConfig(),
                    DocumentationLinks.load(),
                    NewsfeedService.load(),
                ]
            );
            Authentication.initialize(appConfig);
            this.initializeServices(appConfig, documentationLinks, newsfeed);
            if (!Authentication.hasToken()) {
                throw this.AUTHORIZATION_EXCEPTION;
            }

            if (!this.clientId) {
                throw new Error("'clientId' not initialized!");
            }

            if (!this.startup) {
                throw new Error("'startup' not initialized!");
            }

            if (!this.sessionManager) {
                throw new Error("'sessionManager' not initialized!");
            }

            if (!this.planManager) {
                throw new Error("'planManager' not initialized!");
            }

            if (!this.couponManager) {
                throw new Error("'couponManager' not initialized!");
            }

            if (!this.workspacesManager) {
                throw new Error("'workspacesManager' not initialized!");
            }

            if (!this.multiuserStatusManager) {
                throw new Error("'multiuserStatusManager' not initialized!");
            }

            if (!this.pushNotifications) {
                throw new Error("'pushNotifications' not initialized!");
            }

            if (!this.invitation) {
                throw new Error("'invitation' not initialized!");
            }

            if (!this.sessionMonitor) {
                throw new Error("'sessionMonitor' not initialized!");
            }

            if (!this.eventBusService) {
                throw new Error("'eventBusService' not initialized!");
            }

            if (!this.analytics) {
                throw new Error("'analytics' not initialized!");
            }

            if (!this.datadog) {
                throw new Error("'datadog' not initialized!");
            }

            const clientId = await this.clientId.retrieveClientId().toPromise();

            if (!clientId) {
                throw new Error("'clientId' not found!");
            }
            const startupResponse = await forkJoin([
                this.startup.load(clientId),
                ...Apps.load(
                    appConfig,
                    AppLoadStrategyService.getAppLoadStrategy()
                ),
            ])
                .pipe(map(([startup]) => startup))
                .toPromise();

            if (!startupResponse) {
                throw new Error("'startupResponse' not found!");
            }

            this.startupResponse = startupResponse;

            const plan: Plan = this.planManager.parseResponse(
                startupResponse.plan
            );
            const session: Session = await this.sessionManager.parseResponse(
                startupResponse.user,
                startupResponse.session,
                startupResponse.features
            );

            if (!session) {
                throw new Error("'session' not found!");
            }

            if (!plan) {
                throw new Error("'plan' not found!");
            }

            this.clientBasicInfo = Serializer.deserialize<ClientBasicInfoModel>(
                ClientBasicInfoModel,
                startupResponse.clientBasicInfo.body
            );

            this.preferences = Serializer.deserialize<UserPreferenceModel>(
                UserPreferenceModel,
                startupResponse.preferences.body
            );

            this.customColumns = Serializer.deserialize<CustomColumn[]>(
                CustomColumn,
                startupResponse.customColumns?.body?.[
                    "customColumnsDefinitions"
                ] ?? []
            );

            this.customConversion = Serializer.deserialize<CustomConversion[]>(
                CustomConversion,
                startupResponse.customConversions?.body?.[
                    "customConversions"
                ] ?? []
            );

            this.promoSummary = startupResponse.promoSummary
                ? Serializer.deserialize<PromoSummary>(
                      PromoSummary,
                      startupResponse.promoSummary.body
                  )
                : new PromoSummary();

            this.paymentNotification = startupResponse.notification
                ? Serializer.deserialize<PaymentNotification>(
                      PaymentNotification,
                      startupResponse.notification.body
                  )
                : new PaymentNotification();

            this.billingInfo = startupResponse.billingInfo
                ? Serializer.deserialize<BillingInfo>(
                      BillingInfo,
                      startupResponse.billingInfo.body
                  )
                : new BillingInfo();

            this.quotaLimits = startupResponse.entityQuota
                ? Serializer.deserialize<QuotaLimits>(
                      QuotaLimits,
                      startupResponse.entityQuota.body
                  )
                : new QuotaLimits();

            this.timezones = startupResponse.timezones
                ? Serializer.deserialize<TimeZone[]>(
                      TimeZone,
                      startupResponse.timezones.body.timezones
                  )
                : [];

            this.columnOrderTemplates = startupResponse.columnOrderTemplates
                ? Serializer.deserialize<ColumnsOrderTemplate[]>(
                      ColumnsOrderTemplate,
                      startupResponse.columnOrderTemplates.body
                  )
                : [];

            this.firstViewSurvey = startupResponse.firstViewSurvey.body;

            this.onboardingChecklist = startupResponse.onboardingChecklist.body
                ? Serializer.deserialize<OnboardingChecklistModel>(
                      OnboardingChecklistModel,
                      startupResponse.onboardingChecklist.body
                  )
                : new OnboardingChecklistModel();

            this.sessionMonitor.init();
            this.datadog.setUserContext(session);

            this.couponManager.parseResponse(startupResponse.coupon);

            this.workspacesManager.parseResponseAndAssignWorkspaces(
                startupResponse.workspaces
            );

            this.multiuserStatusManager.parseResponse(
                startupResponse.multiUser
            );

            await this.pushNotifications.initialize();

            if (this.invitation.invitationToken) {
                this.invitation.handleInvitationWhenUserLoggedIn(
                    startupResponse.invitation
                );
            }

            LoginHandler.init(
                clientId,
                session.user.email,
                this.eventBusService
            );

            Apps.instantiate(
                appConfig,
                AppLoadStrategyService.getAppLoadStrategy()
            ).forEach((app) => this.bootstrapApp(app));

            this.analytics.initialize(
                session,
                plan,
                this.preferences,
                this.promoSummary
            );
            new PlanMonitor(
                appConfig,
                this.eventBusService,
                this.planManager,
                session
            );

            new Freshdesk(this.eventBusService, session, appConfig);

            // If it was first load strategy, open forms and set handler to load rest app later
            if (AppLoadStrategyService.isFirstLoad()) {
                AppLoadStrategyService.manageFirstLoad(this);
            }
        } catch (exception) {
            this.onError(exception);
        }
    }

    public bootstrapApp(app: IAngularApp): void {
        if (app.isAngularApp) {
            return this.bootstrapAngularApp(app);
        }
    }

    private initializeServices(
        appConfig: IAppConfig,
        documentationLinks: unknown,
        newsfeed: Newsfeed
    ): void {
        const url: Url = new Url();
        this.datadog = new Datadog(appConfig);
        this.appConfig = appConfig;
        this.documentationLinks = documentationLinks;
        this.newsfeed = newsfeed;
        this.invitation = new Invitation(appConfig, url);
        this.startup = new Startup(appConfig, this.invitation);
        this.zpToken = new ZpToken(appConfig, url);
        this.eventBusFactory = new EventBusFactory(
            events,
            Apps.getActiveAppNames(appConfig)
        );
        this.eventBusService = new EventBusService(
            this.eventBusFactory.createEventBusClient(this.APP_NAME)
        );

        this.environment = new Environment(appConfig);
        this.time = new Time();
        const httpRequestErrorHandler: HttpRequestErrorHandler =
            new HttpRequestErrorHandler(this.eventBusService);
        httpRequestErrorHandler.init();
        this.sessionManager = new SessionManager(
            this.eventBusService,
            appConfig,
            this.time,
            url
        );
        this.sessionMonitor = new SessionMonitor(
            appConfig,
            this.eventBusService,
            this.time,
            httpRequestErrorHandler
        );
        this.planManager = new PlanManager();
        this.couponManager = new CouponManager();
        this.workspacesManager = new WorkspacesManager(this.eventBusService);
        this.multiuserStatusManager = new MultiuserStatusManager(
            this.sessionManager
        );
        this.eventWatcher = new EventWatcher(
            this.eventBusService,
            appConfig,
            AppLoadStrategyService.getAppLoadStrategy()
        );
        this.clientId = new ClientId(appConfig, url);
        this.analytics = new Analytics(this.appConfig, this.eventBusService);
        this.pushNotifications = new PushNotifications(
            appConfig,
            this.eventBusService
        );

        new Router(this.eventBusService, appConfig, this.sessionManager);
    }

    private onError(error: Error): void | never {
        if (isAbortedAjaxError(error)) {
            return;
        }

        if (this.isAuthorizationError(error)) {
            return this.handleAuthorizationError();
        }

        FatalErrorComponent.show();

        // Omit ajax errors
        if (!isAjaxError(error)) {
            // Log error to datadog
            this.datadog?.addError(error);
        }

        // For development and SG purposes
        console.error(error);
    }

    private bootstrapAngularApp(app: IAngularApp): void {
        if (
            this.appConfig &&
            this.eventBusFactory &&
            this.sessionManager &&
            this.planManager &&
            this.planManager.plan &&
            this.couponManager &&
            this.time &&
            this.environment &&
            this.workspacesManager &&
            this.newsfeed &&
            this.preferences &&
            this.promoSummary &&
            this.quotaLimits &&
            this.paymentNotification &&
            this.billingInfo
        ) {
            const options: TypedBootstrapOptions = {
                session: this.sessionManager.session,
                plan: this.planManager.plan,
                coupons: this.couponManager.coupons,
                documentationLinks: this.documentationLinks,
                workspacesManager: this.workspacesManager,
                pushNotifications: this.pushNotifications,
                newsfeed: this.newsfeed,
                preferences: this.preferences,
                promoSummary: this.promoSummary,
                quotaLimits: this.quotaLimits,
                paymentNotification: this.paymentNotification,
                billingInfo: this.billingInfo,
                isFirstLoad: AppLoadStrategyService.isFirstLoad(),
                customColumns: this.customColumns,
                customConversions: this.customConversion,
                timezones: this.timezones,
                columnOrderTemplates: this.columnOrderTemplates,
                clientBasicInfo: this.clientBasicInfo,
                firstViewSurvey: this.firstViewSurvey,
                onboardingChecklist: this.onboardingChecklist,
            };

            app.bootstrap(
                {
                    appConfig: this.appConfig,
                    eventBusFactory: this.eventBusFactory,
                    prodMode: this.environment.isProductionMode,
                    time: this.time,
                    authorizationToken: Authentication.getToken(),
                },
                options
            );
        }
    }

    private goToLoginPage(): void {
        if (this.appConfig && this.appConfig.frontend) {
            Navigation.builder()
                .withUrl(this.appConfig.frontend.login)
                .replace();
        }
    }

    private handleAuthorizationError(): void {
        Authentication.removeToken();
        if (this.zpToken && this.zpToken.hasZPToken()) {
            this.zpToken.handleZPToken();
        } else if (this.invitation && this.invitation.invitationToken) {
            this.invitation.handleInvitationWhenUserNotLoggedIn();
        } else {
            this.goToLoginPage();
        }
    }

    private isAuthorizationError(error: Error): boolean {
        return (
            error === this.AUTHORIZATION_EXCEPTION ||
            (isAjaxError(error) &&
                [HttpStatus.UNAUTHORIZED, HttpStatus.FORBIDDEN].includes(
                    error.status
                ))
        );
    }

    private preloadFonts(): void {
        WebFont.load({
            google: {
                families: [
                    "Roboto:300,400,500,700",
                    "Montserrat:300,400,500,700",
                ],
            },
        });
    }
}

function isAjaxError(error: Error): error is AjaxError {
    return error.hasOwnProperty("status");
}

function isAbortedAjaxError(error: Error): boolean {
    return isAjaxError(error) && error.status === XMLHttpRequest.UNSENT;
}

export default App;
