import { IAppConfig } from "@codewise/voluum-frontend-core/app";
import {
    EventName,
    NotificationSubtypeEnum,
} from "@codewise/voluum-frontend-core/events";
import {
    type IPlanJson,
    type IPlanUpgradedEvent,
} from "@voluum-panel-shared/plan-data-access";
import {
    type FeatureToggle,
    type Session,
} from "@voluum-panel-shared/profile-data-access";
import { empty, interval, noop, Observable, Subscription } from "rxjs";
import { catchError, skipWhile, switchMap } from "rxjs/operators";

import {
    AUTHENTICATION_HEADER_NAME,
    AUTHENTICATION_PANEL_HEADER_NAME,
} from "../../../globals";
import { Ajax } from "../../../service/ajax";
import { Authentication, encodeToken } from "../../../service/authentication";
import { EventBus } from "../../../service/event_bus";
import { PlanManager } from "./plan";

const POLL_INTERVAL: number = 10 * 1000;

export class PlanMonitor {
    private pollingSubscription?: Subscription;
    private expectedPlanCode?: string;

    constructor(
        private appConfig: IAppConfig,
        private eventBus: EventBus,
        private planManager: PlanManager,
        private session: Session
    ) {
        this.eventBus.onStartPollingPlanData.subscribe((expectedPlanCode) => {
            this.expectedPlanCode = expectedPlanCode;
            if (!this.pollingSubscription) {
                this.startPollingPlan();
            }
        });
    }

    private startPollingPlan(): void {
        this.pollingSubscription = interval(POLL_INTERVAL)
            .pipe(
                switchMap(() => this.getPlanData()),
                catchError(() => empty()),
                skipWhile(
                    (planData: IPlanJson) =>
                        planData.code !== this.expectedPlanCode
                )
            )
            .subscribe((planData: IPlanJson) => {
                this.planManager.updatePlanData(planData);
                this.pollingSubscription?.unsubscribe();
                this.pollingSubscription = undefined;

                this.getFeatureToggles().subscribe((featuresResponse) => {
                    this.session.currentClient.appendToggles(
                        featuresResponse.features
                    );
                    this.eventBus.showToastNotification({
                        subType: NotificationSubtypeEnum.SUCCESS,
                        message: "Your plan has been successfully upgraded.",
                        closeDelay: 10000,
                    });
                    this.eventBus.dispatch<IPlanUpgradedEvent>({
                        eventName: EventName.PLAN_UPGRADED,
                        data: planData,
                    });
                }, noop);
            });
    }

    private getPlanData(): Observable<IPlanJson> {
        return Ajax.request({
            method: "GET",
            headers: {
                [AUTHENTICATION_HEADER_NAME]: Authentication.getToken(),
                [AUTHENTICATION_PANEL_HEADER_NAME]: encodeToken(
                    Authentication.getToken()
                ),
            },
            url: `${this.appConfig.server.portal}/billing/plan`,
        }) as Observable<IPlanJson>;
    }

    private getFeatureToggles(): Observable<IFeaturesJson> {
        return Ajax.request({
            method: "GET",
            headers: {
                [AUTHENTICATION_HEADER_NAME]: Authentication.getToken(),
                [AUTHENTICATION_PANEL_HEADER_NAME]: encodeToken(
                    Authentication.getToken()
                ),
            },
            url: `${this.appConfig.server.portal}/billing/features`,
        }) as Observable<IFeaturesJson>;
    }
}

interface IFeaturesJson {
    features: FeatureToggle[];
}
