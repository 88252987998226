var _a, _b, _c;
import { __decorate, __metadata } from "tslib";
import { AutoSerialize } from "@codewise/voluum-frontend-framework/repository";
import { OnboardingChecklistAccountModel } from "./account/onboarding-checklist-account.model";
import { OnboardingChecklistAdvancedModel } from "./advanced/onboarding-checklist-advanced.model";
import { OnboardingChecklistBasicsModel } from "./basics/onboarding-checklist-basics.model";
export class OnboardingChecklistModel {
    constructor() {
        this.basics = new OnboardingChecklistBasicsModel();
        this.account = new OnboardingChecklistAccountModel();
        this.advanced = new OnboardingChecklistAdvancedModel();
    }
}
__decorate([
    AutoSerialize(),
    __metadata("design:type", typeof (_a = typeof OnboardingChecklistBasicsModel !== "undefined" && OnboardingChecklistBasicsModel) === "function" ? _a : Object)
], OnboardingChecklistModel.prototype, "basics", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", typeof (_b = typeof OnboardingChecklistAccountModel !== "undefined" && OnboardingChecklistAccountModel) === "function" ? _b : Object)
], OnboardingChecklistModel.prototype, "account", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", typeof (_c = typeof OnboardingChecklistAdvancedModel !== "undefined" && OnboardingChecklistAdvancedModel) === "function" ? _c : Object)
], OnboardingChecklistModel.prototype, "advanced", void 0);
