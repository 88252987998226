import { __decorate, __metadata } from "tslib";
import { AutoSerialize } from "@codewise/voluum-frontend-framework/repository";
export class OnboardingChecklistEventModel {
    constructor() {
        this.checked = false;
        this.lastCheckDate = "";
    }
}
__decorate([
    AutoSerialize(),
    __metadata("design:type", Boolean)
], OnboardingChecklistEventModel.prototype, "checked", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", String)
], OnboardingChecklistEventModel.prototype, "lastCheckDate", void 0);
