import { AutoSerialize } from "@codewise/voluum-frontend-framework/repository";

import { OnboardingChecklistAccountModel } from "./account/onboarding-checklist-account.model";
import { OnboardingChecklistAdvancedModel } from "./advanced/onboarding-checklist-advanced.model";
import { OnboardingChecklistBasicsModel } from "./basics/onboarding-checklist-basics.model";

export class OnboardingChecklistModel {
    @AutoSerialize()
    public basics: OnboardingChecklistBasicsModel =
        new OnboardingChecklistBasicsModel();

    @AutoSerialize()
    public account: OnboardingChecklistAccountModel =
        new OnboardingChecklistAccountModel();

    @AutoSerialize()
    public advanced: OnboardingChecklistAdvancedModel =
        new OnboardingChecklistAdvancedModel();
}
