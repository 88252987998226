import { Moment, MomentFormatSpecification, MomentInput } from "moment";
import { MomentTimezone } from "moment-timezone";

declare function moment(
    inp?: MomentInput,
    format?: MomentFormatSpecification,
    strict?: boolean
): Moment;

// eslint-disable-next-line
declare namespace moment {
    const tz: MomentTimezone;
}
export class Time {
    // Init timezone with browser timezone.
    public userTimeZone: string =
        Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Init server time with browser init time
    private serverTime: number = new Date().getTime();

    public setServerTime(timestamp: number): void {
        this.serverTime = timestamp;
    }

    public getServerTime(): number {
        return this.serverTime;
    }

    public setUserTimeZone(timeZone: string): void {
        this.userTimeZone = timeZone;
    }

    /**
     * Get current user time, in user's timezone, based on server clock
     * (to prevent tampering with user system time)
     */
    public currentUserTime(): Moment {
        return this.convertTimestampToUserTime(this.serverTime).clone();
    }

    /**
     * Create new moment that will be interpreted in server's timezone (UTC)
     *
     * @param userTime
     */
    public convertUserTimeToServerTime(userTime: Moment | number): Moment {
        return moment(userTime.valueOf()).utc();
    }

    /**
     * Create new moment that will be interpreted in user's timezone
     *
     * @param timestamp
     */
    public convertTimestampToUserTime(timestamp: number | string): Moment {
        return moment.tz(timestamp, this.userTimeZone);
    }
}
